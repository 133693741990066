import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Disqus } from 'gatsby-plugin-disqus';

const DisqusComments = ({ title, location }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );
  const { siteUrl } = site.siteMetadata;
  let disqusConfig = {
    url: `${siteUrl + location.pathname}`,
    identifier: location.pathname,
    title: title,
  };

  return <Disqus className="mt-5 rounded-xl bg-white px-5" config={disqusConfig} />;
};

DisqusComments.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DisqusComments;
