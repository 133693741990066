import { Link } from 'gatsby';
import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Image from './Image';

const PostLayout = ({ featuredImage, title, content, date, datetime, previous, next }) => {
  let previousLink;
  let nextLink;

  if (previous && previous?.slug) {
    previousLink = (
      <Link className="pagination-prev" to={`/${previous.frontmatter.slug || previous.slug}`}>
        {previous.frontmatter.title}
      </Link>
    );
  }
  if (next && next?.slug) {
    nextLink = (
      <Link className="pagination-next ml-auto" to={`/${next.frontmatter.slug || next.slug}`}>
        {next.frontmatter.title}
      </Link>
    );
  }

  return (
    <>
      <article className="text-base">
        <Image
          className="mb-10 max-w-sm rounded-lg lg:float-right lg:ml-10 lg:max-w-md"
          filename={featuredImage}
          title={title}
        />

        <header className="mb-5">
          <time className="mb-2 block text-slate-500" dateTime={datetime}>
            {date}
          </time>
          <h1 className="font-semibold capitalize md:text-5xl">{title}</h1>
        </header>
        <MDXRenderer>{content}</MDXRenderer>
      </article>
      <hr className="my-3 border-slate-300 dark:border-slate-700" />
      <div className="flex justify-between">
        {previousLink}
        {nextLink}
      </div>
    </>
  );
};

export default PostLayout;
